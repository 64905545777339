const lang_type = localStorage.getItem("lang") ?? "zh"

const lang_list = {
    "zh": 0,
    "en": 1
}

export const lang = (langLib) => {
    let obj = {}
    for (let i in langLib) {
        obj[i] = langLib[i][lang_list[lang_type]]
    }
    return obj
}
