import {lang} from "./config"
const langLib = {
  "goods_lib": ["商品库", "Goods Library"],
  "goods_add": ["添加商品", "Add Goods"],
  "save_script": ["保存脚本","Save Script"],
  "optimize_script": ["优化脚本","Optimize Script"],
  "serial_number": ["排列序号", "Serial Number"],
  "product_information": ["商品信息", "Product Information"],
  "product_sku": ["商品SKU", "Product SKU"],
  "daily_price": ["日常价", "Daily Price"],
  "live_room_price": ["直播间价", "Live Room Price"],
  "commission_ratio": ["佣金比例(利润率)", "Commission Ratio (Profit Margin)"],
  "operate": ["操作", "Operate"],
  "view_data": ["查看数据", "View Data"],
  "live_streaming_sales_script": ["直播带货脚本", "Live Streaming Sales Script"],
  "retract": ["收起", "Retract"],
  "expand": ["展开", "Expand"],
  "coupon": ["优惠券", "Coupon"],
  "delete_product": ["删除商品", "Delete Product"],
  "product_q_a": ["商品问答", "Product Q&A"],
  "product_selling_points": ["产品卖点", "Product Selling Points"],
  "fill_product_selling_points": ["请填写产品卖点", "Please fill in the product selling point"],
  "already_selected": ["已经选择 ", "Already selected "],
  "items_can_still_select": [" 件商品，还可以选择 ", " items, can still select "],
  "items": [" 件商品", " items"],
  "please_select_a_product": ["请选择商品", "Please select a product"],
  "please_select_the_specified_quantity_of_products": ["请选择指定数量的商品", "Please select the specified quantity of products"],
  "the_number_of_selected_products_reaches_the_limit": ["选择的商品数量达到限制", "The number of selected products reaches the limit"],
  "it_will_not_be_recoverable_after_deletion": ["删除后将无法恢复，确定要删除？", "It will not be recoverable after deletion, sure you want to delete it?"],
  "please_add_products": ["请添加商品", "Please add products"],
  "please_select": ["请选择 ", "Please select "],
  "items_to_add": [" 款商品进行添加", " items to add"],
  "product_selling_points_are_required": ["产品卖点为必填", "Product Selling Points are required"],
  "select_product": ["选择商品", "Select Product"],
  "product_data": ["商品数据", "Product Data"],
  "monthly_sales_trend": ["月销量趋势", "Monthly Sales Trend"],
  "monthly_views_trend": ["月浏览量趋势", "Monthly Views Trend"],
  "monthly_exposure_trend": ["月曝光量趋势", "Monthly Exposure Trend"],
  "transaction_index": ["成交指数", "Transaction Index"],
  "number_of_people": ["人数", "Number Of People"],
  "sales_volume": ["销量", "Sales Volume"],
  "page_view": ["浏览量", "Page View"],
  "exposure": ["曝光量", "Exposure"],
  "transaction_amount": ["成交金额", "Transaction Amount"],
  "transaction_volume": ["成交单量", "Transaction Volume"],
  "number_of_searches": ["搜索人数", "Number of searches"],
  "number_of_visitors": ["访客人数", "Number of visitors"],
}
export default lang(langLib)
