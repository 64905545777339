<template>
  <div class="toggle-language" :class="routeFrom === 'login' ? 'cFixed' : 'cRelative'">
    <el-dropdown>
      <div class="l-text">
        <i class="iconfont">&#xe819;</i>
        <span>{{languageType === 'en' ? 'English' : '简体中文'}}</span>
        <i class="el-icon-arrow-down"></i>
      </div>
      <el-dropdown-menu slot="dropdown" class="language-dropdown">
        <el-dropdown-item @click.native="toggleLanguage('zh')">简体中文</el-dropdown-item>
        <el-dropdown-item @click.native="toggleLanguage('en')">English</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import {switchApi} from '@/utils/apis'
export default {
  name: 'SwitchLanguage',
  props: {
    routeFrom: {
      type: String,
      default: "",
    }
  },
  data() {
    return {
      languageType: localStorage.getItem('lang')
    }
  },
  mounted() {
  },
  methods: {
    toggleLanguage(type) {
      let params = {
        lang: type
      }
      switchApi(params).then(res => {
        if (res.code === 200) {
          window.location.reload()
          this.languageType = res.data.lang
          localStorage.setItem('lang', res.data.lang)
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

.toggle-language {
  width: 90px;
  &.cFixed {
    position: fixed;
    top: 24px;
    right: 24px;
  }
  &.cRelative {
    position: relative;
    top: 15px;
    left: 20px;
  }
  .l-text {
    display: flex;
    align-items: center;
    cursor: pointer;
    line-height: 40px;
    color: #fff;
    span {
      margin: 0 2px;
    }
  }
}
</style>