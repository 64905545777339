import {lang} from "./config"
const langLib = {
  "all_categories": ["全部分类", "All Categories"],
  "please_enter_your_search": ["请输入搜索内容", "Please enter your search"],
  "search_the_whole_site": ["搜全站", "Search the whole site"],
  "search_this_site": ["搜本站", "Search this site"],
  "prices": ["价格", "Prices"],
  "discounted_price": ["折扣价", "Discounted Price"],
  "distribute_to": ["配送   至", "Distribute to"],
  "please_select_a_region": ["请选择地区", "Please select a region"],
  "all_regions": ["全部地区", "All regions"],
  "available": ["有货", "Available"],
  "free_shipping": ["免运费", "Free Shipping"],
  "colors": ["颜色", "Colors"],
  "description_text": ["描述文字", "Description Text"],
  "add_to_cart": ["加入购物车", "Add to cart"],
  "product_details": ["商品详情", "Product Details"],
}
export default lang(langLib)
