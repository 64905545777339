import {lang} from "./config"
const langLib = {
    "e_commerce_live_training_system": ["电商直播实训系统", "E-commerce Live Training System"],
    "goods_add": ["添加商品", "Add Goods"],
    "live_broadcast": ["直播方案","Live Broadcast"],
    "live_selection": ["直播选品","Live Selection"],
    "live_marketing": ["直播营销", "Live Marketing"],
    "live_room_management": ["直播间管理", "Live Room Management"],
    "live_promotion": ["直播间推广", "Live Promotion"],
    "cancel": ["取消", "Cancel"],
    "ok": ["确定", "OK"],
    "alert_message": ["提示消息", "Alert Message"],
    "deleted_successfully": ["删除成功！", "Deleted Successfully!"],
    "deletion_canceled": ["已取消删除", "Deletion canceled"],
    "end_time": ["距离实训结束还有：", "We still have until the end of training:"],
    "entering_practical_training": ["进入实训", "Entering practical training"],
    "view_others_live_broadcasts": ["查看他人直播", "View others' live broadcasts"],
    "training_has_not_begun": ["实训未开始", "Training has not begun"],
    "stay_tuned": ["敬请期待", "Stay tuned"],
}
export default lang(langLib)
