import {lang} from "./config"
const liveMarketingLang = {
    "live_marketing": ["直播营销", "Live marketing"],
    "coupon": ["优惠券", "coupon"],
    "lucky_bag": ["福袋", "Lucky bag"],
    "create_a_coupon": ["创建优惠券", "Create a coupon"],
    "create_a_lucky_bag": ["创建福袋", "Create a lucky bag"],
    "name_of_lucky_bag": ["福袋名称", "Name of lucky bag"],
    "edit_lucky_bag": ["编辑福袋", "Edit lucky bag"],
    "number_of_winners": ["可中奖人数", "Number of winners"],
    "scope_of_participation": ["参与范围", "Scope of participation"],
    "countdown": ["倒计时", "countdown"],
    "password": ["口令", "password"],
    "operate": ["操作", "operate"],
    "edit": ["编辑", "edit"],
    "delete": ["删除", "delete"],
    "there_is_currently_no_data_available": ["暂无数据", "There is currently no data available"],
    "commodity_information": ["商品信息", "commodity information"],
    "coupon_name": ["优惠券名称", "Coupon Name"],
    "coupon_type": ["优惠券类型", "Coupon Type"],
    "quantity_issued": ["发放量", "Quantity issued"],
    "limit_per_person": ["每人限领", "Limit per person"],
    "live_promotion": ["直播促销", "Live promotion"],
    "set_up_coupons": ["设置优惠券", "Set up coupons"],
    "set_coupon_information": ["设置优惠券信息", "Set coupon information"],
    "please_enter_the_coupon_name": ["请输入优惠券名称", "Please enter the coupon name"],
    "please_select_a_coupon_type": ["请选择优惠券类型", "Please select a coupon type"],
    "product_full_amount_reduction_coupon": ["指定商品满减卷", "Designated product full amount reduction coupon"],
    "designated_product_discount_roll": ["指定商品折扣卷", "Designated Product Discount Roll"],
    "please_enter_the_quantity": ["请输入数量", "Please enter the quantity"],
    "sheet": ["张", "sheet"],
    "add_product": ["添加商品", "add product"],
    "list_of_available_products": ["可用商品列表", "List of available products"],
    "selected": ["已选", "selected"],
    "several_items": ["件商品", "Several items"],
    "reduction": ["满减", "reduction"],
    "full": ["满", "full"],
    "reduce": ["减", "reduce"],
    "discount": ["打", "discount"],
    "dollar ": ["元", "Dollar"],
    "convert_into_money": ["折", "convert into money"],
    "address": ["地址", "Address"],
    "cancellation": ["取消", "cancellation"],
    "create": ["创建", "create"],
    "promotional_product": ["促销商品:请选择参与活动的商品", "Promotional Product: Please select the product to participate in the event"],
    "commodity_price": ["商品价格", "commodity price"],
    "increase": ["添加", "increase"],
    "select_product": ["选择商品", "Select product"],
    "blessing_bag_title": ["福袋标题", "Blessing Bag Title"],
    "participation_password": ["参与口令", "Participation password"],
    "please_enter_the_name_of_the_lucky_bag": ["请输入福袋名称", "Please enter the name of the lucky bag"],
    "please_enter_the_number_of_lucky_bags": ["请输入福袋个数", "Please enter the number of lucky bags"],
    "minute": ["分", "minute"],
    "second": ["秒", "second"],
    "number_of_people": ["人数", "Number of people"],
    "selete_scope_of_participation": ["请选择参与范围", "Please select the scope of participation"],
    "select_a_password_to_participate": ["请选择口令参与", "Please select a password to participate"],
    "fan_group_lucky_bag": ["粉丝团福袋", "Fan group lucky bag"],
    "only_fans_can_participate": ["仅限粉丝可参与", "Only fans can participate"],
    "all_lucky_bags": ["全民福袋", "All lucky bags"],
    "all_viewer_can_participate": ["所有观众可参与", "All viewers can participate"],
    "discount_range": ["折扣范围", "Discount range"],
    "one_decimal_place": ["最多一位小数", "One decimal place"],
    "per_person_limit": ["每人限领数量不能大于发放数量", "The limited collection quantity per person cannot be greater than the distribution quantity"],
    "successfully_edited": ["编辑成功", "Successfully edited"],
    "created_successfully": ["创建成功", "Created successfully"],
    "sure_delete_coupon": ["是否确定删除该优惠券，删除后将无法恢复", "Are you sure to delete this coupon? After deletion, it will not be restored"],
    "sure_delete_lucky_bag": ["是否确定删除该福袋，删除后将无法恢复", "Are you sure to delete this lucky bag? After deletion, it will not be restored"],
    "input_time_between": ["输入时间数在0-60之间", "Input time between 0 and 60"],
    "ententer_the_number_of_winnerser": ["请输入可中奖人数", "Please enter the number of winners"],
}
export default lang(liveMarketingLang)
